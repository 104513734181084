import * as i0 from '@angular/core';
import { NgModule, Injectable } from '@angular/core';
import { fromEvent, map, take, finalize } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { asapScheduler } from 'rxjs/internal/scheduler/asap';
import { read, writeFileXLSX } from 'xlsx';
class FileBrowserModule {
  static {
    this.ɵfac = function FileBrowserModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || FileBrowserModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: FileBrowserModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FileBrowserModule, [{
    type: NgModule
  }], null, null);
})();
class FileBrowserService {
  open(options = {}) {
    return this._openFileBrowser(options?.acceptTypes);
  }
  _openFileBrowser(accept) {
    const input = this._createInput(accept);
    const changeEvents = fromEvent(input, 'change');
    const file = changeEvents.pipe(map(_ => input.files), take(1), finalize(() => input.remove()));
    input.click();
    return file;
  }
  _createInput(accept) {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = accept?.join(',') || '';
    return input;
  }
  static {
    this.ɵfac = function FileBrowserService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || FileBrowserService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: FileBrowserService,
      factory: FileBrowserService.ɵfac,
      providedIn: FileBrowserModule
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FileBrowserService, [{
    type: Injectable,
    args: [{
      providedIn: FileBrowserModule
    }]
  }], null, null);
})();
class CsvService {
  constructor(fileBrowser) {
    this.fileBrowser = fileBrowser;
  }
  download(data, name) {
    name = name || data + '';
    const url = typeof data === 'string' ? data : window.URL.createObjectURL(data);
    const a = this._createDownloadElement(url, name);
    a.click();
    window.URL.revokeObjectURL(url);
  }
  downloadCsv(rawCsvText, fileName) {
    const blob = new Blob([rawCsvText], {
      type: 'text/csv'
    });
    this.download(blob, fileName);
  }
  downloadXLSX(rawCsvText, fileName) {
    const book = read(rawCsvText, {
      type: 'string'
    });
    return writeFileXLSX(book, fileName);
  }
  readAsWorkBook(data, opts) {
    return new Observable(sub => {
      const state = {
        data,
        opts
      };
      return asapScheduler.schedule(s => {
        try {
          const book = read(s.data, s.opts);
          sub.next(book);
          sub.complete();
        } catch (err) {
          sub.error(err);
        }
      }, 0, state);
    });
  }
  _createDownloadElement(url, fileName) {
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    console.dir(a);
    return a;
  }
  static {
    this.ɵfac = function CsvService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || CsvService)(i0.ɵɵinject(FileBrowserService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: CsvService,
      factory: CsvService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CsvService, [{
    type: Injectable
  }], () => [{
    type: FileBrowserService
  }], null);
})();
class CsvModule {
  static {
    this.ɵfac = function CsvModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || CsvModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: CsvModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [CsvService],
      imports: [FileBrowserModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CsvModule, [{
    type: NgModule,
    args: [{
      providers: [CsvService],
      imports: [FileBrowserModule]
    }]
  }], null, null);
})();

/*
 * Public API Surface of csv
 */
/**
 * Csv
 */

/**
 * Generated bundle index. Do not edit.
 */

export { CsvModule, CsvService, FileBrowserModule, FileBrowserService };
